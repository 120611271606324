
import { defineComponent, reactive, ref, onMounted, computed, watch, onUnmounted } from 'vue'
import { MutationTypes } from '@/store/users/mutations'
import Button from '@/components/UI/Button.vue'
import InputElement from '@/components/UI/Input.vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Dropdown from '@/components/UI/Dropdown.vue'
import MessageBar from '@/components/UI/MessageBar.vue'
import Popup from '@/components/Popup.vue'
import { carPolicyActionTypes } from '@/store/carPolicy/actions'
import { AuthActionTypes } from '@/store/auth/actions'
import { leaseActionTypes } from '@/store/leaseCategories/actions'
import { UsersActionTypes } from '@/store/users/actions'
import { ActionTypes } from '@/store/options/actions'
import { VueCookieNext } from 'vue-cookie-next'
import Checkbox from '@/components/UI/Checkbox.vue'
import { useStore } from '@/store'
import CurrencyFormat from '@/filters/CurrencyFormat'
import Loader from '@/components/UI/Loader.vue'
import router from '@/router'
import { createEmployeeResponseType } from '@/types'
import formatCurrency from '@/filters/CurrencyFormat'
import _ from 'lodash'
import { USER_STATUS } from '@/composables/staticValues'
import OptionsCheckboxes from '@/components/UI/OptionsCheckboxes.vue'
import getCompany from '@/mixins/getCompany'
export default defineComponent({
  components: {
    Button,
    InputElement,
    Dropdown,
    MessageBar,
    Checkbox,
    Loader,
    Popup,
    OptionsCheckboxes
  },
  name: 'Employee',
  setup() {
    const route = useRoute()
    const store = useStore()
    const showPopup = ref(false)
    const restore = ref(false)
    const { t, locale } = useI18n({ useScope: 'global' })
    const carPolicies = computed(() => store.state.carPolicy.getAllCarPolicies)
    const leaseCategories = computed(() => store.state.leaseCategories.allLeaseCategories)
    const userByIdLoader = computed(() => store.state.users.userByIdLoader)
    const deleteUserResponse = computed(() => store.state.users.deleteUserResponse)
    const userError = computed(() => store.state.users.userError)
    const isUserUpdated = computed(() => store.state.users.isUserUpdated)
    const currentCar = computed(() => store.state.users.orderedCar)
    const { compId, companyDetailState } = getCompany()
    const getAllCarPolicies = computed(() =>
      store.state.carPolicy.getAllCarPolicies.map((item) => {
        return { title: item.policyTitle, id: item.id }
      })
    )
    const getAllLeaseCategories = computed(() =>
      store.state.leaseCategories.allLeaseCategories.map((item) => {
        return { title: item.title, amount: item.amount, id: item.id }
      })
    )
    const getFuelTypes = computed(() => store.state.auth.carPolicyByID.fuelType?.map((item) => item.title))
    const userById = computed(() => store.state.users.userById)
    const currentUserName = computed(() => store.state.auth.currentUser.name)
    const selectedCarPolicy = ref(t('_employees.carPolicy'))
    const userRolesArray: { value: { title: string; id: number }[] } = reactive({ value: [] })
    const savedRoles = ref(JSON.parse(VueCookieNext.getCookie('roles')))
    const getUserRoles = () => {
      let userRoles
      if (savedRoles.value.includes('ROLE_COMPANY_ADMIN')) {
        userRoles = [
          {
            title: 'Company admin',
            id: 2
          },
          {
            title: 'Client',
            id: 3
          },
          {
            title: 'Employee',
            id: 4
          }
        ]
      } else if (savedRoles.value.includes('ROLE_CLIENT')) {
        userRoles = [
          {
            title: 'Client',
            id: 3
          },
          {
            title: 'Employee',
            id: 4
          }
        ]
      } else if (savedRoles.value.includes('ROLE_SUPER_ADMIN')) {
        userRoles = [
          {
            title: 'Company admin',
            id: 2
          },
          {
            title: 'Client',
            id: 3
          },
          {
            title: 'Employee',
            id: 4
          }
        ]
      }
      if (savedRoles.value.includes('ROLE_EMPLOYEE')) {
        userRoles = [
          {
            title: 'Company admin',
            id: 2
          },
          {
            title: 'Client',
            id: 3
          },
          {
            title: 'Employee',
            id: 4
          }
        ]
      }
      return userRoles
    }
    const employeeDetails: createEmployeeResponseType = reactive({
      name: '',
      email: '',
      userStatus: 'selector available',
      employeeNumber: '',
      roles: [] as Array<number>,
      street: '',
      postalCode: '',
      city: '',
      phoneNumber: '',
      country: 1,
      incomeTax: null,
      houseNumber: '',
      privateUse: false,
      expectedMileage: 30000,
      carPolicy: 0,
      leaseCategory: 0,
      allowedFuelType: [] as Array<string>,
      licensePlate: null,
      leaseValidUntil: null,
      sendInvite: 0,
      deletedAt: null
    })
    const employeeDetailsErrors = reactive({})
    const initializeDropdownsRequests = async () => {
      await store.dispatch(carPolicyActionTypes.GET_ALLPOLICIES, { companyId: compId })
      await store.dispatch(leaseActionTypes.GET_ALLCATEGORIES, { companyId: compId })
    }
    const getBreadCrumbTitle = () => {
      let title = t('_employees.newEmployee')
      if (route.params.id) {
        title = t('_employees.editEmployee')
      }
      return title
    }
    const fuelTypeCheckboxes = reactive([
      {
        id: 'step2-styled-checkbox1',
        value: '_wizard.step2.Petrol',
        isActive: false,
        range: false,
        wizardInfo: false,
        title: 'petrol',
        isDisabled: false
      },
      {
        id: 'step2-styled-checkbox2',
        value: '_wizard.step2.Diesel',
        isActive: false,
        range: false,
        wizardInfo: false,
        title: 'diesel',
        isDisabled: false
      },
      {
        id: 'step2-styled-checkbox3',
        value: '_wizard.step2.Electric',
        desc: 'An electric vehicle',
        isActive: false,
        range: true,
        wizardInfo: false,
        sidebarFilter: true,
        title: 'electric',
        isDisabled: false
      },
      {
        id: 'step2-styled-checkbox4',
        value: '_wizard.step2.PlugInHybrid',
        desc:
          'A plug-in hybrid electric vehicle is a hybrid electric vehicle whose battery can be recharged by plugging it into an external source of electric power, as well as by its on-board engine and generator.',
        isActive: false,
        range: true,
        wizardInfo: false,
        sidebarFilter: true,
        title: 'plug_in_hybrid',
        isDisabled: false
      },
      {
        id: 'step2-styled-checkbox5',
        value: '_wizard.step2.Hybrid',
        desc: 'A hybrid vehicle',
        isActive: false,
        range: false,
        wizardInfo: false,
        title: 'hybrid',
        isDisabled: false
      },
      {
        id: 'step2-styled-checkbox6',
        value: '_wizard.step2.Hydrogen',
        desc: 'A Hydrogen vehicle',
        isActive: false,
        range: false,
        wizardInfo: false,
        title: 'hydrogen',
        isDisabled: false
      },
      {
        id: 'step2-styled-checkbox7',
        value: '_wizard.step2.Other',
        desc: 'Other vehicle',
        isActive: false,
        range: false,
        wizardInfo: false,
        title: 'other',
        isDisabled: false
      }
    ])
    const checkAndDisableFuelTypes = () => {
      fuelTypeCheckboxes.forEach((item) => {
        item.isActive = false
        item.isDisabled = false
        if (getFuelTypes.value.includes(item.title)) {
          item.isActive = true
        } else {
          item.isDisabled = true
        }
      })
    }
    const getCarPolicyByID = async (carPolicyId) => {
      await store.dispatch(AuthActionTypes.GET_CARPOLICY_BY_ID, {
        companyId: compId,
        carPolicyId: carPolicyId
      })
      checkAndDisableFuelTypes()
    }
    const convertToNumber = (selectedOption) => {
      return parseInt(selectedOption.replace(/[kmmonths<>]/g, ''))
    }
    const selectedLeaseCategory = ref(t('_employees.leaseCategory'))
    watch(
      () => _.cloneDeep(locale.value),
      () => {
        if (selectedCarPolicy.value === 'Car policy' || selectedCarPolicy.value == 'Autobeleid') {
          selectedCarPolicy.value = t('_employees.carPolicy')
        }
        if (selectedLeaseCategory.value === 'Lease category' || selectedLeaseCategory.value == 'Lease categorie') {
          selectedLeaseCategory.value = t('_employees.leaseCategory')
        }
      }
    )
    const selectedRole = ref('Select a role')
    const onInputEmployeeDetails = (value, key) => {
      employeeDetails[key] = value
      if (key == 'licensePlate') {
        employeeDetails[key] = value.replace(/-|\s/g, '')
      }
    }
    const MileageRange = reactive([
      '5000 km',
      '10000 km',
      '15000 km',
      '20000 km',
      '25000 km',
      '30000 km',
      '35000 km',
      '40000 km',
      '45000 km',
      '50000 km',
      '55000 km',
      '60000 km',
      '65000 km',
      '70000 km',
      '75000 km',
      '80000 km'
    ])
    const selectMileageRange = (value) => {
      employeeDetails['expectedMileage'] = convertToNumber(value)
    }
    const statusItems = reactive([
      USER_STATUS.SELECTOR_AVAILABLE,
      USER_STATUS.CAR_ORDERED,
      USER_STATUS.ACTIVE_LEASE,
      USER_STATUS.DEACTIVATED_ACCOUNT,
      USER_STATUS.NO_LEASE_CAR
    ])
    const selectStatus = (item) => {
      employeeDetails['userStatus'] = item
    }
    const selectCarPolicy = async (item) => {
      selectedCarPolicy.value = item['title']
      employeeDetails['carPolicy'] = item.id
      await getCarPolicyByID(item.id)
      employeeDetails['allowedFuelType'] = getFuelTypes.value
    }
    const selectLeaseCategory = (item) => {
      if (item.amount) selectedLeaseCategory.value = `${item['title']} (${CurrencyFormat(item.amount)})`
      else selectedLeaseCategory.value = `${item['title']}`
      employeeDetails['leaseCategory'] = item.id
    }
    const onCheck = (event, item) => {
      if (employeeDetails['allowedFuelType'].indexOf(item) === -1) {
        employeeDetails['allowedFuelType'].push(item)
      }
      if (!event.target.checked) {
        employeeDetails['allowedFuelType'].splice(employeeDetails['allowedFuelType'].indexOf(item), 1)
      }
    }
    const selectRole = (role) => {
      const findIndex = userRolesArray.value.findIndex((item) => item['id'] === role['id'])
      if (findIndex === -1) {
        userRolesArray.value.push(role)
        employeeDetails['roles'].push(role.id)
      }
    }
    const removeUserRole = (role, index) => {
      if (userRolesArray.value.indexOf(role) !== -1) {
        userRolesArray.value.splice(index, 1)
        employeeDetails['roles'].splice(index, 1)
      }
    }
    const validateEmail = (value) => {
      // eslint-disable-next-line no-useless-escape
      const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}')
      return regex.test(value)
    }
    const validatePhoneNumber = (value) => {
      // validate dutch numbers
      const regex = new RegExp(
        '(^[+][0-9]{2}|^[+][0-9]{2}(0)|^([+][0-9]{2})(0)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9-s]{10}$)'
      )
      return regex.test(value)
    }
    const scrollToSection = (sectionId) => {
      const element = document.querySelector(`#${sectionId}`) as HTMLElement
      element.scrollIntoView({
        behavior: 'smooth'
      })
    }
    const validateSections = () => {
      delete employeeDetailsErrors['nameError']
      delete employeeDetailsErrors['emailError']
      delete employeeDetailsErrors['emailValidationError']
      delete employeeDetailsErrors['rolesError']
      delete employeeDetailsErrors['streetError']
      delete employeeDetailsErrors['houseNumberError']
      delete employeeDetailsErrors['postalCodeError']
      delete employeeDetailsErrors['cityError']
      delete employeeDetailsErrors['phoneNumberError']
      delete employeeDetailsErrors['phoneNumberValidationError']
      delete employeeDetailsErrors['carPolicyError']
      delete employeeDetailsErrors['leaseCategoryError']
      delete employeeDetailsErrors['allowedFuelTypeError']
      delete employeeDetailsErrors['licensePlateError']
      let sectionId = ''
      if (employeeDetails.licensePlate && employeeDetails.licensePlate.length !== 6) {
        employeeDetailsErrors['licensePlateError'] = 'required'
        sectionId = 'leaseCarDetails'
      }
      if (employeeDetails.roles.includes(4)) {
        if (!employeeDetails.carPolicy) {
          employeeDetailsErrors['carPolicyError'] = 'required'
          sectionId = 'carPolicyDetails'
        }
        if (!employeeDetails.leaseCategory) {
          employeeDetailsErrors['leaseCategoryError'] = 'required'
          sectionId = 'carPolicyDetails'
        }
        if (getFuelTypes.value && !employeeDetails.allowedFuelType.length) {
          employeeDetailsErrors['allowedFuelTypeError'] = 'required'
          sectionId = 'carPolicyDetails'
        }
      }
      if (!employeeDetails.street) {
        employeeDetailsErrors['streetError'] = 'required'
        sectionId = 'contactDetails'
      }
      if (!employeeDetails.houseNumber) {
        employeeDetailsErrors['houseNumberError'] = 'required'
        sectionId = 'contactDetails'
      }
      if (!employeeDetails.postalCode) {
        employeeDetailsErrors['postalCodeError'] = 'required'
        sectionId = 'contactDetails'
      }
      if (!employeeDetails.city) {
        employeeDetailsErrors['cityError'] = 'required'
        sectionId = 'contactDetails'
      }
      if (!employeeDetails.phoneNumber) {
        employeeDetailsErrors['phoneNumberError'] = 'required'
        sectionId = 'contactDetails'
      }
      if (employeeDetails.phoneNumber && !validatePhoneNumber(employeeDetails.phoneNumber)) {
        employeeDetailsErrors['phoneNumberValidationError'] = 'required'
        sectionId = 'contactDetails'
      }
      if (!employeeDetails.name) {
        employeeDetailsErrors['nameError'] = 'required'
        sectionId = 'accountDetails'
      }
      if (!employeeDetails.email) {
        employeeDetailsErrors['emailError'] = 'required'
        sectionId = 'accountDetails'
      }
      if (employeeDetails.email && !validateEmail(employeeDetails.email)) {
        employeeDetailsErrors['emailValidationError'] = 'required'
        sectionId = 'accountDetails'
      }
      if (!employeeDetails.roles.length) {
        employeeDetailsErrors['rolesError'] = 'required'
        sectionId = 'accountDetails'
      }
      if (Object.getOwnPropertyNames(employeeDetailsErrors).length === 0) {
        return true
      } else {
        scrollToSection(sectionId)
      }
    }
    const saveUser = async () => {
      if (validateSections()) {
        if (!employeeDetails.licensePlate) {
          employeeDetails['licensePlate'] = null
        }
        if (!employeeDetails.leaseValidUntil) {
          employeeDetails['leaseValidUntil'] = null
        }
        if (route.params.id) {
          delete employeeDetails['sendInvite']
          await store.dispatch(UsersActionTypes.UPDATE_USERBYID, {
            companyId: compId,
            userId: route.params.id,
            body: employeeDetails
          })
          const rolesArray = ['ROLE_USER']
          if (employeeDetails['roles'].includes(2)) {
            rolesArray.push('ROLE_COMPANY_ADMIN')
          }
          if (employeeDetails['roles'].includes(3)) {
            rolesArray.push('ROLE_CLIENT')
          }
          if (employeeDetails['roles'].includes(4)) {
            rolesArray.push('ROLE_EMPLOYEE')
          }
          if (isUserUpdated.value && currentUserName.value == employeeDetails.name) {
            VueCookieNext.setCookie('roles', JSON.stringify(rolesArray), {
              expire: '24h',
              path: '/',
              domain: '',
              secure: '',
              sameSite: ''
            })
          }
        } else {
          store.dispatch(UsersActionTypes.ADD_USER, {
            companyId: compId,
            body: employeeDetails,
            lang: localStorage.getItem('locale') || 'nl'
          })
        }
      }
    }
    const getRolesByID = (roles) => {
      const userRoles = roles.filter((value) => value !== 'ROLE_USER')
      const selectedRoles: { title: string; id: number }[] = []
      if (userRoles.includes('ROLE_COMPANY_ADMIN')) {
        selectedRoles.push({
          title: 'Company admin',
          id: 2
        })
      }
      if (userRoles.includes('ROLE_EMPLOYEE')) {
        selectedRoles.push({
          title: 'Employee',
          id: 4
        })
      }
      if (userRoles.includes('ROLE_CLIENT')) {
        selectedRoles.push({
          title: 'Client',
          id: 3
        })
      }
      return selectedRoles
    }
    const getUserById = async () => {
      await store.dispatch(UsersActionTypes.GET_USERBYID, {
        companyId: compId,
        userId: route.params.id
      })
      employeeDetails.name = userById.value.name
      employeeDetails.email = userById.value.email
      if (userById.value.userStatus) employeeDetails.userStatus = userById.value.userStatus
      employeeDetails.employeeNumber = userById.value.employeeNumber
      if (userById.value.roles) userRolesArray.value = getRolesByID(userById.value.roles)
      employeeDetails.roles = userRolesArray.value.map((item) => item['id'])
      employeeDetails.street = userById.value.street
      employeeDetails.houseNumber = userById.value.houseNumber
      employeeDetails.postalCode = userById.value.postalCode
      employeeDetails.city = userById.value.city
      employeeDetails.phoneNumber = userById.value.phoneNumber
      if (userById.value.carPolicy) {
        selectedCarPolicy.value = userById.value.carPolicy.policyTitle
        employeeDetails.carPolicy = userById.value.carPolicy.id ?? 0
        employeeDetails.allowedFuelType = userById.value.allowedFuelType
        await getCarPolicyByID(employeeDetails.carPolicy)
        fuelTypeCheckboxes.forEach((item) => {
          item.isActive = false
          if (employeeDetails.allowedFuelType.includes(item.title)) {
            item.isActive = true
          }
        })
      }
      if (userById.value.leaseCategory) {
        const leaseItem = userById.value.leaseCategory
        if (leaseItem['amount'])
          selectedLeaseCategory.value = `${leaseItem['title']} (${CurrencyFormat(leaseItem['amount'])})`
        else selectedLeaseCategory.value = `${leaseItem['title']}`

        employeeDetails.leaseCategory = userById.value.leaseCategory.id
      }
      if (userById.value.expectedMileage) {
        employeeDetails.expectedMileage = userById.value.expectedMileage
      }
      employeeDetails.licensePlate = userById.value.licensePlate
      employeeDetails.leaseValidUntil = userById.value.leaseValidUntil
      if (userById.value.incomeTax) employeeDetails.incomeTax = userById.value.incomeTax.id
      employeeDetails.privateUse = userById.value.privateUse
      employeeDetails.sendInvite = userById.value.sendInvite
      employeeDetails.deletedAt = userById.value.deletedAt
    }
    const dateFormat = (date) => {
      const dateArr = date.split(' ')
      return dateArr[0].split('-').reverse().join('-') + ' ' + dateArr[1].split(':').slice(0, -1).join(':')
    }
    const closePopup = () => {
      showPopup.value = false
    }
    const discardUser = () => {
      router.push({ name: 'Employees', query: { page: 1 } })
    }
    const onProceed = () => {
      store.dispatch(UsersActionTypes.DELETE_USERBYID, {
        companyId: compId,
        userId: route.params.id
      })
      showPopup.value = false
    }
    const onProceedRestore = () => {
      store.dispatch(UsersActionTypes.RESTORE_USERBYID, {
        companyId: compId,
        userId: route.params.id
      })
      restore.value = false
    }
    const deleteUser = () => {
      showPopup.value = true
    }
    const restoreUser = () => {
      restore.value = true
    }
    const closeRestorePopup = () => {
      restore.value = false
    }
    const onCheckSendInviteUser = (value) => {
      employeeDetails.sendInvite = value.option.target.checked ? 1 : 0
    }
    const downloadCarOrderedPdf = () => {
      store.dispatch(ActionTypes.DOWNLOAD_CARORDERPDF, {
        lang: localStorage.getItem('locale') || 'nl',
        carOrderId: currentCar.value.id
      })
    }
    onMounted(async () => {
      if (route.params.id) {
        await getUserById()
      }
      await initializeDropdownsRequests()
    })
    onUnmounted(() => {
      store.commit(MutationTypes.SET_DELETEUSERBYIDRESPONSE, '')
      store.commit(MutationTypes.SET_USER_ERROR, '')
      store.commit(MutationTypes.SET_ISUSERUPDATED, false)
      store.commit(MutationTypes.SET_ORDERED_CAR, {})
    })
    return {
      getBreadCrumbTitle,
      statusItems,
      selectStatus,
      selectedRole,
      selectRole,
      getAllCarPolicies,
      getAllLeaseCategories,
      selectedCarPolicy,
      selectedLeaseCategory,
      selectCarPolicy,
      selectLeaseCategory,
      fuelTypeCheckboxes,
      onCheck,
      getUserRoles,
      userRolesArray,
      removeUserRole,
      MileageRange,
      selectMileageRange,
      employeeDetails,
      onInputEmployeeDetails,
      getFuelTypes,
      saveUser,
      employeeDetailsErrors,
      validateEmail,
      userById,
      userByIdLoader,
      route,
      discardUser,
      deleteUser,
      deleteUserResponse,
      showPopup,
      closePopup,
      onProceed,
      restoreUser,
      restore,
      onProceedRestore,
      closeRestorePopup,
      userError,
      carPolicies,
      leaseCategories,
      onCheckSendInviteUser,
      currentCar,
      formatCurrency,
      downloadCarOrderedPdf,
      savedRoles,
      dateFormat,
      companyDetailState
    }
  }
})
